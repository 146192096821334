header {
    margin: 5px;
}

header h1 {
    margin: 20px;
    text-align: center;
    font-size: 70px;
    font-family: 'Caveat', cursive;
}

.wrapper {
    display: flex;
}

.add-task {
    width: 70%;
    margin: 0 auto;
}


input {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #80CFA9;
    border-right: none;
    background: white;
}


input.search-input {
    margin: 20px;
    height: 20px;
    width: 20%;
    padding: 10px;
    border: 1px solid #80CFA9;
    border-radius: 50px;
    background: white;
}

@Media (max-width: 1100px){
    input.search-input {
        width: 30%;
    }
}

@Media (max-width: 600px){
    header h1 {
        font-size: 40px;
    }

    input.search-input {
        width: 60%;
    }

}

